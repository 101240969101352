import { EventEmitter, Injectable } from '@angular/core';
import { bonusDesc } from '../../interfaces/interfaces';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { HelpersService } from '../helpers/helpers.service';
import { UserCurrencyPipePipe } from '../../pipes/userCurrencyPipe/user-currency-pipe.pipe';

@Injectable({
  providedIn: 'root',
})
export class BonusesService {
  bonus$: EventEmitter<any> = new EventEmitter<any>();
  bonusRegistration$: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private helpers: HelpersService
  ) {}

  getBonuses(lang: string = '') {
    if (lang == '') {
      lang = this.helpers.getCookie('userlang');
    }
    return this.http.get<[]>(`/bonuses?lang=${lang}&type=all`);
  }

  getPromoCodeBonus(promocode: string) {
    return this.http.get(`/bonuses?PromoCode=${promocode}`);
  }

  filterPromoBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem.PromoCode !== 1);
  }

  filterBonuses(bonuses: any, type: string = 'all') {
    if (type !== 'all') {
      bonuses = bonuses.filter((elem: any) => elem?.Event?.indexOf(type) > -1);
    }
    bonuses = bonuses.map((elem: any) => ({
      ...elem,
      Name: this.setBonusName(elem),
      readMore: false,
      bonusDesc: this.setBonusDesc(elem),
    }));
    return bonuses;
  }

  filterByType(bonuses: Array<any>, type: string) {
    return bonuses.filter((elem: any) => elem?.Description?.includes(type));
  }

  setBonusName(bonuses: any) {
    const userCurrency = this.localStorage.getSessionStorage('currency') || 'USD';
    const desc = bonuses?.Terms;
    const regex = /name=([^=]+?)\s*({.*?})\s*(\+\s*\d+\s*FS)?/i;
    const match = desc?.match(regex);
    if (!match) {
      return bonuses.Name;
    }

    try {
      const bonusName = match[1].trim();
      const currencyObject = JSON.parse(match[2]);
      const currencyValue = currencyObject[userCurrency] || currencyObject['undefined'];
      const freeSpins = match[3] ? ` ${match[3].replace(/\s+/g, '')}` : '';
      return `${bonusName} ${currencyValue}${freeSpins}`;
    } catch (error) {
      console.error('Invalid JSON:', error);
      return bonuses.Name;
    }
  }

  setBonusDesc(bonuses: any) {
    const userCurrency = this.localStorage.getSessionStorage('currency') || 'USD';
    const result: Array<bonusDesc> = [];
    if (bonuses?.AmountMin[userCurrency]) {
      result.push(
        this.setDescLine(
          'min_deposit',
          'https://cdn.chipychapa.com//welle/General_site/Bonuses/min_deposit.svg',
          bonuses?.AmountMin[userCurrency]
        )
      );
    }
    const desc = bonuses?.Terms;
    // desc.split(' ').forEach((elem: any) => {
    if (desc.indexOf('fsgame=') > -1) {
      result.push({
        name: 'game',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/game.svg',
        subtext: desc.split('=')[1],
      });
    }
    if (bonuses?.Description?.includes('FSbonus') && bonuses?.Results?.freerounds?.Value) {
      result.push({
        name: 'spins',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/fs_icon.svg',
        subtext: bonuses?.Results?.freerounds?.Value,
      });
    }
    if (bonuses?.Description?.includes('cashback') && bonuses?.Target?.includes('balance')) {
      result.push({
        name: 'bonus_amount',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/game.svg',
        subtext: `${bonuses?.Results?.balance?.Value[userCurrency]} ${userCurrency}`,
      });
    }
    if (bonuses?.Description?.includes('Turnover') && bonuses?.Active) {
      result.push({
        name: 'progress',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/game.svg',
        subtext: `${(bonuses?.WageringTotal - bonuses?.WageringLeft).toFixed(2)} ${userCurrency} / 
        ${bonuses?.WageringTotal} ${userCurrency}`,
      });
    }
    const cashbackBonuses = [182465, 182102, 182099, 182096, 182093, 181919, 180332];
    if (cashbackBonuses.includes(bonuses?.ID)) {
      result.push({
        name: 'bonus.withdrawal_limit',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/withdrawal_limit.svg',
        subtext: 'x10 ',
      });
    }
    // });

    if (bonuses?.Terms?.includes('wager')) {
      const match = bonuses.Terms.match(/wager=x?(\d+)/);
      if (match) {
        result.push({
          name: 'wager',
          img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/wager.svg',
          subtext: `x${match[1]}`,
        });
      }
    } else if (bonuses?.Results?.balance?.AwardWagering?.COEF || bonuses?.Results?.freerounds?.AwardWagering?.COEF) {
      const resultCOEF = bonuses?.Results?.balance?.AwardWagering?.COEF;
      const finalCOEF =
        resultCOEF === undefined || resultCOEF === '' ? bonuses?.Results?.freerounds?.AwardWagering?.COEF : resultCOEF;

      result.push({
        name: 'wager',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/wager.svg',
        subtext: `x${finalCOEF}`,
      });
    }
    result.push(
      this.setDescLine(
        'max_bet',
        'https://cdn.chipychapa.com//welle/General_site/Bonuses/max_bet.svg',
        bonuses?.MaxBet[userCurrency] === '' ? '∞' : bonuses?.MaxBet[userCurrency]
      )
    );

    if (bonuses?.Results?.balance?.LimitValue[userCurrency]) {
      result.push(
        this.setDescLine(
          'max_bonus',
          'https://cdn.chipychapa.com//welle/General_site/Bonuses/max_bonus.svg',
          bonuses?.Results?.balance?.LimitValue[userCurrency]
        )
      );
    }

    result.push(
      this.setDescLine(
        'expires',
        'https://cdn.chipychapa.com//welle/General_site/Bonuses/expires.svg',
        bonuses?.ExpireDays
      )
    );
    return result;
  }

  setDescLine(name: string, img: string, subtext: string) {
    return {
      name,
      img,
      subtext,
    };
  }

  filterActiveBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem?.Active === 1);
  }

  filterSubscriptionBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem?.Selected !== 1 || (elem?.Selected === 1 && elem?.PromoCode === 1));
  }

  chunkAmount(elem: any, userCurrency: string) {
    let limit = 0;
    let result: number =
      elem?.Event.indexOf('deposit') > -1
        ? Number(elem?.Bonus) / 10
        : Math.floor(elem?.Results?.balance?.Value[userCurrency] / 10);

    if (elem?.Event.indexOf('deposit') > -1) {
      limit = elem?.Results?.balance?.LimitValue[userCurrency];

      if (result > Number(limit) / 10) {
        result = Number(limit) / 10;
      }
    }
    return result;
  }
  leftToGet(elem: any, userCurrency: string) {
    let limit = 0;
    let result: number =
      elem?.Event.indexOf('deposit') > -1
        ? Number(elem?.Bonus) -
          (Number(elem?.Bonus) / 10) * Math.floor(((elem.WageringTotal - elem.WageringLeft) / elem.WageringTotal) * 10)
        : Math.floor(elem?.Results?.balance?.Value[userCurrency]) -
          Math.floor(
            (elem?.Results?.balance?.Value[userCurrency] / 10) *
              Math.floor(((elem.WageringTotal - elem.WageringLeft) / elem.WageringTotal) * 10)
          );
    if (elem?.Event.indexOf('deposit') > -1) {
      limit = elem?.Results?.balance?.LimitValue[userCurrency];
      if (result > Number(limit)) {
        result = Number(limit);
      }
    }
    return result;
  }

  getCurrentBonusBalance(bonusState: any) {
    if (bonusState?.length === 0 || bonusState === undefined) return;
    bonusState = bonusState?.filter((el: any) => el.Active == 1);
    if (
      Object.values(bonusState).length == 1 &&
      Number(bonusState[0].BonusBalance) <= 0 &&
      bonusState[0].Event.toLowerCase() === 'store'
    ) {
      this.cancelBonusId(bonusState[0].ID).subscribe(() => null);
      return;
    }
  }

  cancelBonusId(id: number) {
    return this.http.delete(`/bonuses/${id}`);
  }
  findBonusById(bonuses: any, id: string) {
    return bonuses.find((elem: any) => elem.ID === id);
  }

  subscribeToBonus(id: string, code: string) {
    return this.http.post(`/bonuses/${id}`, { Selected: 1, PromoCode: code });
  }

  unsubscribeToBonus(id: string) {
    return this.http.post(`/bonuses/${id}`, {
      Selected: 0,
    });
  }

  sendBonus(bonus: any) {
    this.bonus$.emit(bonus);
  }
  sendBonusRegistration(bonus: any) {
    this.bonusRegistration$.emit(bonus);
  }

  checkIfBonusIsWelcome(bonus: string): boolean {
    const bonusIds = [
      '131750',
      '120614',
      '118178',
      '133874',
      '133871',
      '134189',
      '159446',
      '160982',
      '160430',
      '160418',
      '159431',
      '159299',
      '159176',
      '139616',
      '157187',
      '162854',
      '162416',
    ];
    return !bonusIds.some((bonusId) => bonus.includes(bonusId));
  }

  getCashbackBonus(balance: number, currency: string = 'USD', amount: number) {
    const cashbackTiers = [
      { min: 2500000, name: 'cashback.poseidon', desc: 'cashback.15_percent', img: 'cashback_Poseidon.png' },
      { min: 500000, name: 'cashback.triton', desc: 'cashback.13_percent', img: 'cashback_Triton.png' },
      { min: 200000, name: 'cashback.admiral', desc: 'cashback.11_percent', img: 'cashback_Admiral.png' },
      { min: 50000, name: 'cashback.captain', desc: 'cashback.10_percent', img: 'cashback_Captain.png' },
      { min: 5000, name: 'cashback.boatswain', desc: 'cashback.9_percent', img: 'cashback_Boatswain.png' },
      { min: 0, name: 'cashback.sailor', desc: 'cashback.8_percent', img: 'cashback_Sailor.png' },
    ];

    const tier = cashbackTiers.find((t) => balance >= t.min) || cashbackTiers[cashbackTiers.length - 1];

    return {
      Name: tier.name,
      cashbackDesc: tier.desc,
      Image: `https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/${tier.img}`,
      bonusDesc: [
        { name: 'bonus.wager', img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/wager.svg', subtext: 'x5' },
        {
          name: 'bonus.withdrawal_limit',
          img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/withdrawal_limit.svg',
          subtext: 'x10 ',
        },
        {
          name: 'bonus.amount',
          img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/cashback1.svg',
          subtext: `${new UserCurrencyPipePipe().transform(currency) + amount.toFixed(2)}`,
        },
      ],
    };
  }
}
